import { newMeasure } from "@gooddata/sdk-model";
import { useBackend } from "@gooddata/sdk-ui";
import { Loading, Error, KPICallout } from '../../design-system';
import { useState, useEffect } from 'react';
import { getSmartValue } from '../../global/utils';

interface Props {
    type: string;
    filters?: any;
    workspace: string;
};

const organizeData = (data: any) => {
    return {
        incrementalCost: parseFloat(data.data[0][0]),
        utilitySavings: parseFloat(data.data[1][0]),
        carbonCredits: parseFloat(data.data[2][0])
    }
};

const PlanningKPIs = ({ workspace, filters }: Props) => {

    const backend = useBackend();
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(false);

    useEffect(() => {

        setData(undefined);

        const incrementalCost = newMeasure("acywjXTyZd5W", m => m.aggregation("sum"));
        const utilitySavings = newMeasure("aawD55JtJ531", m => m.aggregation("sum"));
        const carbonCredits = newMeasure("abSuXuER6skw", m => m.aggregation("sum"));

        const getData = async () => {

            let result;
            try {
                result = await backend
                    .workspace(workspace)
                    .execution()
                    .forItems([
                        incrementalCost,
                        utilitySavings,
                        carbonCredits
                    ], filters)
                    .execute();

                const newData = await result.readAll()
                return organizeData(newData);
            } catch (e) {
                console.error("PKPI ERROR:\n", e);
            }
        }

        getData().then(newData => setData(newData));

    }, [filters, backend, workspace]);

    if (error) {
        return (
            <Error />
        )
    }

    if (data === undefined) {
        return (
            <Loading />
        )
    }

    console.log("PKPI -> smarty pants:", getSmartValue(data.incrementalCost, "money"))

    return (
        <div className="flex-row flex-space-around flex-align-stretch flex-wrap">
            <KPICallout
                title="Total Incremental Project Costs"
                width="var(--size-xxl)" height="var(--size-xl)"
                value={getSmartValue(data.incrementalCost, "money")}
                units="$ CAD"
                tooltipContent={{
                    "Annualized Costs": getSmartValue(data.incrementalCost / 28, "money"),
                    "Over": "28 Years"
                }} />
            <KPICallout
                title="Net Utility Cost Savings"
                width="var(--size-xxl)" height="var(--size-xl)"
                value={getSmartValue(data.utilitySavings, "money")}
                units="$ CAD"
                tooltipContent={{
                    "Annualized Savings": getSmartValue(data.utilitySavings / 28, "money"),
                    "Over": "28 Years"
                }} />

            {
                !isNaN(data.carbonCredits) &&
                <KPICallout
                    title="Carbon Credits to Net Zero"
                    width="var(--size-xxl)" height="var(--size-xl)"
                    value={getSmartValue(data.carbonCredits, "money")}
                    units="$ CAD"
                    tooltipContent={{
                        "Annualized Savings": getSmartValue(data.carbonCredits / 28, "money"),
                        "Over": "28 Years"
                    }}
                />
            }
        </div>
    );
}
export default PlanningKPIs;
