import { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

interface Props {
    children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
    return (
        <Auth0Provider
            domain="audette.auth0.com"
            clientId="PNyAKFmjc1M6Q6tY1VLKpvt5oG26SVZA"
            redirectUri={ window.location.origin }
            audience="https://audette-auth-service"
            scope=""
        >
            { children }
        </Auth0Provider>
    )
}

export default AuthProvider;