import "./insightTooltip.css"

interface InsightTooltipProps {
    color?: string;
    data:{[key: string]: string | number | JSX.Element };
    xOffset?: string | number;
    yOffset?: string | number;
    style?: any
}


const InsightTooltip = ({style, color, data}:InsightTooltipProps) => {

    const useStyle = {...style}
    
    useStyle["borderTop"] = '4px solid ' + color;
    
    return (
        <div className="aud-insight-tooltip"
             style={useStyle}>
            {Object.entries(data).map(([label, value], index) => {
            return (
            <div key={index} className="aud-insight-tooltip-group">
                <div className="aud-label">{label}:</div>
                <div>{value}</div>
            </div>
            )
            })}
        </div>
    )
}
export default InsightTooltip;
