import React from 'react';
import { Tag as TagAD } from 'antd';
import 'antd/dist/antd.css';
import './tag.css';

export interface TagProps {

    primary?: boolean;
    closable?: boolean;
    backgroundColor?: string;
    color?: string;
    children?: React.ReactNode;
    onClose?: any;
    
}

/**
 * Primary UI component for user interaction
 */
export const Tag = ({primary = false, closable = true, color, backgroundColor, children, onClose = () => null}: TagProps) => {

    if (color === undefined) {
        color = primary ? 'var(--audette-white)' : 'var(--audette-black)'
    }

    if (backgroundColor === undefined) {
        backgroundColor = primary ? 'var(--audette-hot-pink)' : 'var(--audette-light-teal-secondary)'
    }
    
    const tagStyle = {
        color: color,
        backgroundColor: backgroundColor,
        border: `1px solid ${backgroundColor}`,    
    }

    return (
        <TagAD
            closable={closable}
            className={'aud-tag'}
            style={tagStyle}
            onClose={onClose}>
            {children}
        </TagAD>
    );
};
