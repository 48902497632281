import { Layout } from 'antd';
const { Content } = Layout;

interface Props {
    customerData: any
}

const Workspace = ({ customerData }: Props) => {

    // I have no idea why this height == 500 sets the content to the height of the page,
    // minus the Header size (wraps navbar), but it seems to work so...

    const workspace = customerData.workspace_uid

    return (
        <Content style={{height: 500}}>
        <iframe
            src={ "https://audette.on.gooddata.com/dashboards/embedded/#/workspace/" +
                    workspace + "?showNavigation=true&setHeight=1000"
            }
            title="Gooddata Workspace"
            height="100%"
            width="100%"
            frameBorder="0"
        ></iframe>
        </Content>
    );
}

export default Workspace;
