import React from 'react';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import './search.css';

const { Option } = Select;

export interface SearchProps {
    /**
     * Options for the search input
     */
    options?: Array<string>;
    /**
     * Value selected in the search input
     */
    value?: Array<string>;
    /**
     * Disabled search input or not
     */
    disabled?: boolean;
    /**
     * Disabled search input or not
     */
    onChange?: ((value: string[], option: any) => void);
}

const generateOptions = (options: any) => {

    return options.map((option:any, index:any) => {
        return <Option value={option} key={index}>{option}</Option>
    });
}

/**
 * Primary UI component for user interaction
 */
export const Search = ({
    options,
    value,
    disabled = false,
    onChange
}: SearchProps) => {
    return (
        <Select
            showSearch
            mode="tags"
            className='audette-search'
            placeholder="Search"
            value={ value }
            disabled={ disabled }
            onChange={ onChange }
        >
            { generateOptions(options) } 
        </Select>
    );
};
