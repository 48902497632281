import {NavLink} from "react-router-dom";
import {Layout} from "antd";
import {Logo, Text, UserPopover} from "../../../design-system/";

import "./header.css";
import FeatureController from "../../atoms/FeatureController";

const {Header} = Layout;

export interface CustomerHeaderProps {
    onLogout?: any;
    customerData: any;
}

export const CustomerHeader = ({
    customerData,
    onLogout,
}: CustomerHeaderProps) => {
    return (
        <>
            <Header className="nav-bar">
                <div className="menu">
                    <NavLink to="/" className="menu-item">
                        <Logo color="white" />
                    </NavLink>

                    <FeatureController
                        featureName="page-explorer"
                        customerData={customerData}
                        child={
                            <NavLink
                                to="/explorer"
                                className={(state) =>
                                    state.isActive
                                        ? "menu-item-active"
                                        : "menu-item"
                                }
                            >
                                <Text size="large" color="white">
                                    Explorer
                                </Text>
                            </NavLink>
                        }
                    />

                    <FeatureController
                        featureName="page-explorer"
                        customerData={customerData}
                        child={
                            <NavLink
                                to="/market"
                                className={(state) =>
                                    state.isActive
                                        ? "menu-item-active"
                                        : "menu-item"
                                }
                            >
                                <Text size="large" color="white">
                                    Market
                                </Text>
                            </NavLink>
                        }
                    />

                    <FeatureController
                        featureName="page-planning"
                        customerData={customerData}
                        defaultShow={true}
                        child={
                            <NavLink
                                to="/planning"
                                className={(state) =>
                                    state.isActive
                                        ? "menu-item-active"
                                        : "menu-item"
                                }
                            >
                                <Text size="large" color="white">
                                    Planning
                                </Text>
                            </NavLink>
                        }
                    />

                    <FeatureController
                        featureName="page-monitoring"
                        customerData={customerData}
                        defaultShow={true}
                        child={
                            <NavLink
                                to="/monitoring"
                                className={(state) =>
                                    state.isActive
                                        ? "menu-item-active"
                                        : "menu-item"
                                }
                            >
                                <Text size="large" color="white">
                                    Monitoring
                                </Text>
                            </NavLink>
                        }
                    />

                    <FeatureController
                        featureName="page-dashboards"
                        customerData={customerData}
                        defaultShow={true}
                        child={
                            <NavLink
                                to="/dashboards"
                                className={(state) =>
                                    state.isActive
                                        ? "menu-item-active"
                                        : "menu-item"
                                }
                            >
                                <Text size="large" color="white">
                                    Dashboards
                                </Text>
                            </NavLink>
                        }
                    />

                    <div className="menu-last-item">
                        <UserPopover
                            customerName={customerData?.name}
                            onClickLogout={onLogout}
                        />
                    </div>
                </div>
            </Header>
            <div style={{height: 60}} />
        </>
    );
};
