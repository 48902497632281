import { getFeatureFlaggingConfig } from "../../global/api";
import { useState, useEffect } from 'react';


interface featureControllerProps {
    featureName: string,
    customerData?: any,
    child: JSX.Element,
    loading?: JSX.Element,
    defaultShow?: boolean,
    onAuthDenied?: Function,
    authDeniedChild?: JSX.Element,
}

// Use this to easily turn on and off features.
const FeatureController = ({defaultShow, loading, featureName, customerData, child, onAuthDenied, authDeniedChild}: featureControllerProps) => {

    const [featureRules, setFeatureRules] = useState(null);
    useEffect(() => {
        if (customerData !== undefined && customerData.uid !== undefined) {
            getFeatureFlaggingConfig(
                customerData.uid,
                setFeatureRules,
                (e) => console.log('Failed to load feature flagging configuration:', e)
            )
        }
    }, [customerData])
    
    if (featureRules === null || featureRules === undefined) {

        if (defaultShow) {
            return child;
        }
        return loading || null;
    }
        
    if (featureRules[featureName]) {
        return child;
    }
    
    if (featureRules[featureName] === undefined) {
        console.warn('Feature "' + featureName + '" is not included in the feature flags.')
        if (defaultShow) {
            return child;
        } 
            
    }

    if (onAuthDenied !== undefined) {
        onAuthDenied();
    }
    return authDeniedChild || null;
    
}
export default FeatureController;
 
