import "@gooddata/sdk-ui-dashboard/styles/css/main.css";

import CustomerSearch from "../../components/organisms/CustomerSearch"
import RadioControlledInsight from "../../components/molecules/RadioControlledInsight"
import GoodDataInsight from '../../components/molecules/GoodDataInsight';
import Sankey from '../../components/customInsights/EndUseSankey'
import DecarbonizationRoadmap from '../../components/customInsights/DecarbonizationRoadmap'
import PlanningKPIs from '../../components/customInsights/PlanningKPIs';
import { newPositiveAttributeFilter, newNegativeAttributeFilter } from "@gooddata/sdk-model";
import { Title } from "../../design-system";
import { useState, useEffect } from 'react';

interface Props {
    buildings: any;
    selectedBuildings: Array<string>;
    setSelectedBuildings: Function;
    selectedTags: Array<string>;
    setSelectedTags: Function;
    workspace: string;
}

const radioButtonOptions = [
    { label: "Carbon", value: "carbon" },
    { label: "Energy", value: "energy" },
    { label: "Cost", value: "cost" }
]


const createFilters = (selectedBuildings: string[]) => {

    const buildingNameDisplayForm = "label.out_building_information.building_name"

    if (selectedBuildings.length === 0) {
        return [newNegativeAttributeFilter(buildingNameDisplayForm, selectedBuildings)];
    }
    return [newPositiveAttributeFilter(buildingNameDisplayForm, selectedBuildings)];
}


const PlanningOverview = ({ buildings, selectedBuildings, setSelectedBuildings, selectedTags, setSelectedTags, workspace }: Props) => {

    const [filters, setFilters] = useState(createFilters(selectedBuildings));
    useEffect(() => {
        setFilters(createFilters(selectedBuildings));
    }, [selectedBuildings])

    console.log("PlanningOverview -> workspace:", workspace);

    return (
        <>
            <div style={{ height: 540, position: 'relative' }}>
                <div style={{ position: 'absolute', top: 30, left: 30, zIndex: 10 }}>
                    <CustomerSearch
                        extra="Clear"
                        buildings={buildings}
                        selectedBuildings={selectedBuildings}
                        setSelectedBuildings={setSelectedBuildings}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                    />
                </div>
                <GoodDataInsight
                    filters={filters}
                    config={{ legend: { enabled: false } }}
                    insight={"MapBox"} />
            </div>

            <div className="insight">
                <Title>Decarbonization Roadmap</Title>
                <DecarbonizationRoadmap
                    filters={filters}
                    type="carbon"
                    workspace={workspace} />
            </div>


            <PlanningKPIs
                workspace={workspace}
                filters={filters} />

            <div className="insight">
                <Title>Carbon Reduction Measures</Title>
                <GoodDataInsight
                    filters={filters}
                    config={{ legend: { enabled: false } }}
                    insight={"CarbonReductionMeasures"} />
            </div>

            <RadioControlledInsight
                title={{ energy: "Energy Consumption", carbon: "Carbon Emissions", cost: "Energy Consumption Costs" }}
                options={radioButtonOptions}
                render={(option) => <Sankey
                    workspace={workspace}
                    type={option}
                    filters={filters} />} />
        </>
    );
}

export default PlanningOverview;
