import "@gooddata/sdk-ui-dashboard/styles/css/main.css";

import CustomerSearch from "../../components/organisms/CustomerSearch"
import RadioControlledInsight from "../../components/molecules/RadioControlledInsight"
import GoodDataInsight from '../../components/molecules/GoodDataInsight';
import BenchmarkBarChart from '../../components/customInsights/BenchmarkBarChart'

import { newPositiveAttributeFilter, newNegativeAttributeFilter } from "@gooddata/sdk-model";
import { useState, useEffect } from 'react';

interface Props {
    buildings: any;
    selectedBuildings: Array<string>;
    setSelectedBuildings: Function;
    selectedTags: Array<string>;
    setSelectedTags: Function;
    workspace: string;
}

const energySourceOptions = [
    { label: "All Energy Sources", value: "ConsumptionMonitoringAllSources" },
    { label: "Electricity", value: "ConsumptionMonitoringElectricity" },
    { label: "Natural Gas", value: "ConsumptionMonitoringNaturalGas" }
]

const radioButtonOptions = [
    { label: "Carbon", value: "carbon" },
    { label: "Energy", value: "energy" },
    { label: "Cost", value: "cost" }
]

const equipmentOptions = [
    { label: "Heating Plants", value: "Heating Plants" },
    { label: "Cooling Plants", value: "Cooling Plants" },
    { label: "Ventilation Systems", value: "Ventilation Systems" },
    { label: "DHW Systems", value: "DHW Systems" },
    { label: "Envelope", value: "Envelope" },
    { label: "Lighting", value: "Lighting" },
    { label: "Cooling Tower", value: "Cooling Tower" },
    { label: "Pumps", value: "Pumps" },
]



const createFilters = (selectedBuildings: string[]) => {

    const buildingNameDisplayForm = "label.out_building_information.building_name"

    if (selectedBuildings.length === 0) {
        return [newNegativeAttributeFilter(buildingNameDisplayForm, selectedBuildings)];
    }
    return [newPositiveAttributeFilter(buildingNameDisplayForm, selectedBuildings)];
}


const MonitoringOverview = ({ buildings, selectedBuildings, setSelectedBuildings, selectedTags, setSelectedTags, workspace }: Props) => {

    const [filters, setFilters] = useState(createFilters(selectedBuildings));
    useEffect(() => {
        setFilters(createFilters(selectedBuildings));
    }, [selectedBuildings])

    return (
        <>
            <div style={{ height: 540, position: 'relative' }}>
                <div style={{ position: 'absolute', top: 30, left: 30, zIndex: 10 }}>
                    <CustomerSearch
                        extra="Clear"
                        buildings={buildings}
                        selectedBuildings={selectedBuildings}
                        setSelectedBuildings={setSelectedBuildings}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                    />
                </div>
                <GoodDataInsight
                    filters={filters}
                    config={{ legend: { enabled: false } }}
                    insight={"MapBox"} />
            </div>

            <RadioControlledInsight
                title={{
                    "ConsumptionMonitoringAllSources": "Energy Source: Electricity & Natural Gas (kWh)",
                    "ConsumptionMonitoringElectricity": "Energy Source: Electricity (kWh)",
                    "ConsumptionMonitoringNaturalGas": "Energy Source: Natural Gas (kWh)",
                }}
                options={energySourceOptions}
                render={(option) => <GoodDataInsight
                    filters={filters}
                    config={{ legend: { enabled: false } }}
                    insight={option} />
                }
            />


            <RadioControlledInsight
                title={{
                    energy: "Energy Consumption Benchmarks (kWh / m2)",
                    carbon: "Carbon Emissions Benchmarks (kg CO2 / m2)",
                    cost: "Energy Cost Benchmarks ($ / m2)"
                }}
                options={radioButtonOptions}
                render={(option) =>
                    <BenchmarkBarChart
                        workspace={workspace}
                        type={option}
                        filters={filters} />
                } />

            <RadioControlledInsight
                title={{
                    "Heating Plants": "Equipment: Heating Plants",
                    "Cooling Plants": "Equipment: Cooling Plants",
                    "Ventilation Systems": "Equipment: Ventilation Systems",
                    "DHW Systems": "Equipment: DHW Systems",
                    "Envelope": "Equipment: Envelope",
                    "Lighting": "Equipment: Lighting",
                    "Cooling Tower": "Equipment: Cooling Tower",
                    "Pumps": "Equipment: Pumps",
                }}
                options={equipmentOptions}
                render={(option) => <GoodDataInsight
                    filters={filters}
                    config={{ legend: { enabled: false } }}
                    insight={option} />
                } />

        </>
    );
}
export default MonitoringOverview;
