import "./heatmap-control.scss";
import Dropdown from "../../../design-system/components/atoms/Dropdown";
import {
    MetricType,
    getMetricDisplayInfo,
    MetricDisplayInfo,
} from "../../../global/metric";

interface HeatmapControlProps {
    onSetMetric: (metricKey: string) => void;
    metrics: {key: string; label: string}[];
    selectedMetric: {key: string; label: string; metricType: MetricType | null};
    currentMetricMinMax: {min: number; max: number};
}

export const defaultHeatmapColors = [
    "#066ecc",
    "#436ad2",
    "#6665d5",
    "#855dd5",
    "#a253d0",
    "#bd44c8",
    "#d530bc",
    "#eb03ad",
]

const HeatmapControl = ({
    onSetMetric,
    metrics,
    selectedMetric,
    currentMetricMinMax,
}: HeatmapControlProps) => {
    return (
        <div className="heatmap-control">
            <Dropdown
                dropdownItems={metrics}
                onDropdownItemSelect={onSetMetric}
                selectedItem={selectedMetric}
            />
            <HeatmapLegend
                min={currentMetricMinMax.min}
                max={currentMetricMinMax.max}
                metricType={selectedMetric.metricType}
            />
        </div>
    );
};

const HeatmapLegend = ({
    min,
    max,
    metricType,
}: {
    min: number;
    max: number;
    metricType: MetricType | null;
}) => {
    let minMetricDisplayInfo;
    let maxMetricDisplayInfo;
    if (metricType) {
        minMetricDisplayInfo = getMetricDisplayInfo(min, metricType);
        maxMetricDisplayInfo = getMetricDisplayInfo(max, metricType);
    }

    const getMinMaxDisplay = ({
        displayValue,
        prefix,
        suffix,
    }: MetricDisplayInfo) => {
        return `${prefix}${displayValue} ${suffix}`;
    };

    return (
        <div className="heatmap-legend">
            <div className="heatmap-legend-row">
                {`${
                    minMetricDisplayInfo
                        ? getMinMaxDisplay(minMetricDisplayInfo)
                        : min
                }`}
                <LegendColors />
                {`${
                    maxMetricDisplayInfo
                        ? getMinMaxDisplay(maxMetricDisplayInfo)
                        : max
                }`}
            </div>
            {metricType && metricType}
        </div>
    );
};

const LegendColors = () => {
    return (
        <div className="colors">
            {defaultHeatmapColors.map((color) => {
                return (
                    <div className="color" style={{background: `${color}`}} />
                );
            })}
        </div>
    );
};

export default HeatmapControl;
