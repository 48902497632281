let ENV: string = "";
let BRICK_BACKEND_DOMAIN: string = "";
let DOMAIN: string = "";
let GD_DOMAIN: string = "";
let AUTH_DOMAIN: string = "";
let EXPLORE_API_DOMAIN = "";

if (process.env.NODE_ENV === "development") {
    console.log('global/constants -> ENV = "development"');
    ENV = "development";
    BRICK_BACKEND_DOMAIN = "http://127.0.0.1:5000";
    DOMAIN = "https://localhost:3000";
    GD_DOMAIN = "https://localhost:3000";
    AUTH_DOMAIN = "http://localhost:5001";
    EXPLORE_API_DOMAIN = "http://localhost:5005";
} else {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        console.log('global/constants -> ENV = "production"');
        ENV = "production";
        BRICK_BACKEND_DOMAIN = "https://brick-backend-xtacj7i4tq-nn.a.run.app";
        DOMAIN = "https://beta.audette.io/";
        GD_DOMAIN = "https://audette.on.gooddata.com";
        AUTH_DOMAIN = "https://auth-service-lzt4crddlq-nn.a.run.app";
        EXPLORE_API_DOMAIN = "https://explore-backend-lecujd76oa-uw.a.run.app";
    } else {
        // default to staging
        console.log('global/constants -> ENV = "staging"');
        ENV = "staging";
        BRICK_BACKEND_DOMAIN = "https://brick-backend-xtacj7i4tq-nn.a.run.app";
        DOMAIN = "https://customer-ui-vpe5pfzowa-uw.a.run.app/";
        GD_DOMAIN = "https://audette.on.gooddata.com";
        AUTH_DOMAIN = "https://auth-service-lzt4crddlq-nn.a.run.app";
        EXPLORE_API_DOMAIN = "https://explore-backend-lecujd76oa-uw.a.run.app";
    }
}
export {
    ENV,
    DOMAIN,
    BRICK_BACKEND_DOMAIN,
    GD_DOMAIN,
    AUTH_DOMAIN,
    EXPLORE_API_DOMAIN,
};
