import { Title } from "../../design-system";

const Empty = () => {
    return (
        <div
            style={
                {
                    backgroundColor: "white",
                    padding: "75px 25px",
                    textAlign: "center"
                }
            }
        >
            <Title>
                Your guide to carbon free real estate is currently being generated. Please check back soon.
            </Title>
        </div>
    );
}

export default Empty;
