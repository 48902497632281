import {DownOutlined} from "@ant-design/icons";
import {Dropdown as AndtDropdown, Menu, Space} from "antd";
import {useState} from "react";

interface DropdownProps {
    dropdownItems: {label: string; key: string}[];
    onDropdownItemSelect: (key: string) => void;
    selectedItem: {label: string; key: string};
}

const Dropdown = ({
    dropdownItems,
    onDropdownItemSelect,
    selectedItem,
}: DropdownProps) => {
    const [visible, setVisible] = useState(false);

    const handleMenuClick = (e: any) => {
        if (e) onDropdownItemSelect(e.key);
        setVisible(false);
    };

    const menu = <Menu onClick={handleMenuClick} items={dropdownItems} selectedKeys={[selectedItem.key]}/>;
    return (
        <AndtDropdown
            overlay={menu}
            onVisibleChange={setVisible}
            visible={visible}
            trigger={["click"]}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    {selectedItem.label}
                    <DownOutlined />
                </Space>
            </a>
        </AndtDropdown>
    );
};

export default Dropdown;
