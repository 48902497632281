import {UserOutlined} from "@ant-design/icons";
import {ReactNode} from "react";
import {Button} from "../../../";

import Popover from "../../molecules/Popover";

interface UserPopoverProps {
    customerName: ReactNode | (() => ReactNode);
    onClickLogout: () => void;
}

const UserPopover = ({customerName, onClickLogout}: UserPopoverProps) => {
    return <Popover
        title={customerName}
        content={
            <Button onClick={onClickLogout}>Log Out</Button>
        }
        trigger="hover"
        placement="bottomRight"
    >
        <div className="menu-item">
            <UserOutlined
                style={{fontSize: "var(--size-s)"}}
            />
        </div>
    </Popover>
};

export default UserPopover;
