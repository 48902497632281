import {Map, Marker, GoogleApiWrapper} from "google-maps-react";
import {useEffect, useState, useMemo} from "react";
import {CloseOutlined} from "@ant-design/icons";

import Dot from "./images/dot.png";
import BuildingDetail from "../../components/organisms/BuildingDetail";
import MapControls from "../../components/molecules/MapControls";
import KPICard, {KPICardData} from "../../components/molecules/KPICard";

import {buildingFootprints} from "../../components/molecules/MapRegions";
import {getAllFilters, queryDataForMap} from "../../global/api";

import {buildKPIDataExplore} from "../../components/molecules/KPICard/helpers";

interface Props {
    customerData: any;
    google: any;
}

const Explore = ({customerData, google}: Props) => {
    const [buildings, setBuildings] = useState([]);
    const [filters, setFilters] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState<any>(null);
    const [showBuildingFootprints, setShowBuildingFootprints] = useState(false);
    const [filteredKPICardBuildingData, setFilteredKPICardBuildingData] =
        useState<KPICardData | null>(null);

    const [currentZoom, setCurrentZoom] = useState(4);
    const [mapReference, setMapReference] = useState<any>();

    useEffect(() => {
        getAllFilters(setFilters, console.error);
    }, []);

    // this use effect is the set the kpi card data when the map is finished loading and the building data has been fetched
    useEffect(() => {
        if (mapReference?.getBounds()) {
            setFilteredKPICardBuildingData(
                buildKPIDataExplore(buildings, mapReference.getBounds())
            );
        }
    }, [buildings, mapReference]);

    const queryData = useMemo(() => {
        return queryDataForMap(setBuildings, console.error);
    }, [setBuildings]);

    const mapOnIdle = (_, map) => {
        setCurrentZoom(map.zoom);
        setFilteredKPICardBuildingData(
            buildKPIDataExplore(buildings, map.getBounds())
        );
    };

    const renderMarkers = () => {
        if (currentZoom <= 8)
            return buildings.map((building: any) => (
                <Marker
                    key={"dot-" + building["id"]}
                    onClick={() => setSelectedBuilding(building)}
                    position={{
                        lat: parseFloat(building["latitude"]),
                        lng: parseFloat(building["longitude"]),
                    }}
                    icon={{
                        url: Dot,
                        // anchor: new google.maps.Point(32,32),
                        scaledSize: new google.maps.Size(4, 4),
                    }}
                />
            ));
        else {
            return buildings.map((building: any) => (
                <Marker
                    key={"pin-" + building["id"]}
                    onClick={() => setSelectedBuilding(building)}
                    position={{
                        lat: parseFloat(building["latitude"]),
                        lng: parseFloat(building["longitude"]),
                    }}
                />
            ));
        }
    };

    return (
        <div className="flex-row flex-stretch flex-align-stretch">
            <div className="flex-grow-2">
                <MapControls
                    filters={filters}
                    hidden={false}
                    queryData={queryData}
                    showBuildingFootprints={showBuildingFootprints}
                    setShowBuildingFootprints={setShowBuildingFootprints}
                />
                {filteredKPICardBuildingData && (
                    <KPICard data={filteredKPICardBuildingData} />
                )}
                <div className="full-page-height width-100p overflow-hidden position-absolute">
                    <Map
                        onIdle={mapOnIdle}
                        google={google}
                        initialCenter={{
                            lat: 49.5262,
                            lng: -101.4929,
                        }}
                        mapTypeControlOptions={{
                            position: google.maps.ControlPosition.TOP_RIGHT,
                        }}
                        scaleControl
                        fullscreenControl={false}
                        streetViewControl={false}
                        style={{
                            height: "calc(100vh - 60px)",
                        }}
                        zoom={4}
                        onReady={(_: any, map) => setMapReference(map)}
                        zoomControlOptions={{
                            position: google.maps.ControlPosition.TOP_RIGHT,
                        }}
                    >
                        {!showBuildingFootprints && renderMarkers()}
                        {showBuildingFootprints && buildingFootprints()}
                    </Map>
                </div>
            </div>
            <BuildingDetail
                building={selectedBuilding}
                customerData={customerData}
                large={true}
                extra={
                    <CloseOutlined
                        onClick={() => setSelectedBuilding(null)}
                        style={{
                            color: "black",
                            fontSize: "24px",
                            margin: "15px",
                            float: "right",
                        }}
                    />
                }
            />
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyAHjrNBzQbo3Lp2jsdgQ8e9u3GLRHhTvvo",
    libraries: ["visualization"],
})(Explore);
