import { ResponsivePie } from "@nivo/pie";
import { newMeasure, newAttribute } from "@gooddata/sdk-model";
import { useBackend } from "@gooddata/sdk-ui";
import { Loading, Error, InsightTooltip } from '../../design-system';
import { useState, useEffect } from 'react';

interface Props {
    type: string;
    filters?: any;
    workspace: string;
    optional?: boolean;
    micro?: boolean;
};

const identifiers : any = {
    "energy": "fact.out_end_use_table.energy_use_kwh",
    "cost":   "fact.out_end_use_table.energy_cost_cad",
    "carbon": "fact.out_end_use_table.emissions_kg_co2",
}; 

const globalIds : any = {
    "energy": "m_fact.out_end_use_table.energy_use_kwh_sum",
    "cost":   "m_fact.out_end_use_table.energy_cost_cad_sum",
    "carbon": "m_fact.out_end_use_table.emissions_kg_co2_sum",
};

const units : any = {
    "energy": "kWh / year",
    "cost":   "CAD / year",
    "carbon": "Kg CO2 / year",
}

const colours = [
    
    "var(--audette-purple)", // Purple
    "var(--audette-light-teal-secondary)", // Light Teal
    "var(--audette-black)", // Black
    "var(--audette-light-orange)", // Light Orange
    "var(--audette-hot-pink)", // Hot Pink
    "var(--audette-concrete)"  // Concrete
];

const organizeData = (data) => {
    
    let endUses = {};
    
    // Organize Data
    data.data.forEach((measureData, measureIndex) => {
        measureData.forEach((datum, dataIndex) =>{

            const endUse = data.headerItems[1][0][dataIndex].attributeHeaderItem.name;
            if (endUses[endUse + data.headerItems[0][0][measureIndex].measureHeaderItem.name] === undefined) {
                endUses[endUse + data.headerItems[0][0][measureIndex].measureHeaderItem.name] = {
                    "id": endUse,
                    "label": endUse,
                    "type": data.headerItems[0][0][measureIndex].measureHeaderItem.name,
                    "value": 0
                }
            }
            
            endUses[endUse + data.headerItems[0][0][measureIndex].measureHeaderItem.name]["value"] += parseFloat(datum);
        });
    })
    
    return Object.entries(endUses).map(([field, value]) => value);
};

const renderTooltip = (node: any) => {

    // @ts-ignore
    const chartType = {
        "m_fact.out_end_use_table.energy_use_kwh_sum": "energy",
        "m_fact.out_end_use_table.energy_cost_cad_sum": "cost",
        "m_fact.out_end_use_table.emissions_kg_co2_sum": "carbon"
    }[node.datum.data.type];

    return (
        <InsightTooltip
            color={node.datum.color}
            data={{
                "End Use": node.datum.data.label,
                "Quantity": (chartType === "cost" ? "$" : "") + node.datum.data.value.toLocaleString() + " " + units[chartType],
            }} />
    )

}

const PieChart = ({ workspace, type, filters, optional = false, micro = false }:Props) => {

    const backend = useBackend();
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(false);
    
    useEffect(() => {

        if (filters.length === 0) {
            return;
        }

        setData(undefined);

        const consumption  = newMeasure(identifiers["energy"], m => m.aggregation("sum"));
        const cost         = newMeasure(identifiers["cost"],   m => m.aggregation("sum"));
        const emissions    = newMeasure(identifiers["carbon"], m => m.aggregation("sum"));
        const endUses      = newAttribute("label.out_end_use_table.end_use");
        const utilityTypes = newAttribute("label.out_end_use_table.utility");

        const getData = async () => { 
            let result;
            try {
                result = await backend
                    .workspace(workspace)
                    .execution()
                    .forItems([consumption, cost, emissions, endUses, utilityTypes], filters)
                    .execute();

                const newData = await result.readAll()
                return organizeData(newData);
            } catch (e) {
                console.error("EUS ERROR:\n", e);
                setError(true);
            }
        }

        getData().then(newData => setData(newData));

    }, [filters, backend, workspace]);        
    
    if (error) {
        if (optional) return null;
        return (
            <Error micro={micro} />
        )
    }

    if (data === undefined || filters.length === 0) {
        if (optional) return null;
        return (
            <Loading />
        )
    }

    const pieData = data.filter((datum) => datum.type === globalIds[type]);
    
    return (
        <ResponsivePie
            data={pieData}
            margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
            innerRadius={0.2}
            colors={colours}
            padAngle={1}
            cornerRadius={5}
            activeOuterRadiusOffset={5}
            activeInnerRadiusOffset={5}
            borderWidth={1}
            enableArcLabels={false}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            tooltip={(node) => renderTooltip(node)}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
        />
    );
}
export default PieChart;

