import { Title } from "../../design-system";

const AccessDenied = () => {
    return (
        <div className="flex-column flex-space-around">
            <Title>
                Your account does not have permission to view this page.
            </Title>
            <Title size="small">
                Please contact Audette if this is an error.
            </Title>
        </div>
    );
}

export default AccessDenied;
