import {useAuth0} from "@auth0/auth0-react";
import {WorkspaceProvider, useBackend} from "@gooddata/sdk-ui";
import {useEffect, useState} from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {Layout} from "antd";

import {CustomerHeader} from "./components/organisms/Header/";
import AccessDenied from "./pages/AccessDenied/";
import Explore from "./pages/Explore/";
import Monitoring from "./pages/Monitoring/Monitoring";
import Planning from "./pages/Planning/Planning";
import Empty from "./pages/Empty/empty";
import {Loading} from "./design-system";
import Workspace from "./pages/Workspace/workspace";
import {getCustomerData, getBuildings} from "./global/api";
import {login, logout} from "./global/authFunctions";
import FeatureController from "./components/atoms/FeatureController";
import MarketIntelligence from "./pages/MarketIntelligence";

// TODO: if no customer ID, no routes should load.

const App = () => {
    const {logout: auth0Logout} = useAuth0();
    const backend = useBackend();

    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [selectedTags, setSelectedTags]: [
        selectedTags: Array<string>,
        setSelectedTags: Function
    ] = useState([]);
    const [selectedBuildings, setSelectedBuildings]: [
        selectedTags: Array<string>,
        setSelectedTags: Function
    ] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [customerData, setCustomerData] = useState<any>(null);

    useEffect(() => {
        login(backend, setAuthenticated);
    }, [backend]);

    useEffect(() => {
        if (authenticated === true) {
            const callGetCustomerData = async () => {
                const user = await backend!.currentUser().getUser();
                getCustomerData(
                    user.email || "",
                    (data: any) => setCustomerData(data[0]),
                    () =>
                        console.error(
                            "Failed to load user information from server"
                        )
                );
            };
            callGetCustomerData();
        }
    }, [authenticated, backend]);

    useEffect(() => {
        console.log("App -> customerData:", customerData);
        if (customerData !== null) {
            getBuildings(
                customerData.uid,
                (data: any) => {
                    setBuildings(data);
                },
                () =>
                    console.error(
                        "Failed to load building information from the server"
                    )
            );
        }
    }, [customerData]);

    if (
        authenticated === false ||
        customerData === null ||
        customerData === undefined
    ) {
        return (
            <div style={{height: "100vh"}}>
                <CustomerHeader onLogout={() => logout(auth0Logout, backend)} />
                <Loading />
            </div>
        );
    }

    if (customerData.workspace_uid === null) {
        return (
            <>
                <CustomerHeader onLogout={() => logout(auth0Logout, backend)} />
                <Routes>
                    <Route path="/" element={<Empty />} />
                </Routes>
            </>
        );
    }

    return (
        <Layout className="layout">
            <CustomerHeader
                customerData={customerData}
                onLogout={() => logout(auth0Logout, backend)}
            />

            <WorkspaceProvider workspace={customerData.workspace_uid}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <FeatureController
                                featureName="page-explorer"
                                customerData={customerData}
                                loading={<Loading size="page-height" />}
                                child={<Navigate to="/explorer" />}
                                authDeniedChild={
                                    <FeatureController
                                        featureName="page-planning"
                                        customerData={customerData}
                                        loading={<Loading size="page-height" />}
                                        child={<Navigate to="/planning" />}
                                        authDeniedChild={<Navigate to="/dashboards" />}
                                />}
                            />
                        }
                    />

                    <Route
                        path="/dashboards"
                        element={
                            <FeatureController
                                featureName="page-dashboards"
                                customerData={customerData}
                                loading={<Loading size="page-height" />}
                                child={
                                    <Workspace customerData={customerData} />
                                }
                                authDeniedChild={<AccessDenied />}
                            />
                        }
                    />
                    <Route
                        path="/monitoring"
                        element={
                            <FeatureController
                                featureName="page-monitoring"
                                customerData={customerData}
                                loading={<Loading size="page-height" />}
                                child={
                                    <Monitoring
                                        buildings={buildings}
                                        customerData={customerData}
                                        selectedBuildings={selectedBuildings}
                                        setSelectedBuildings={
                                        setSelectedBuildings
                                        }
                                        selectedTags={selectedTags}
                                        setSelectedTags={setSelectedTags}
                                    />
                                }
                                authDeniedChild={<AccessDenied />}
                            />
                        }
                        authDeniedChild={<AccessDenied />}
                    />
                    <Route
                        path="/planning"
                        element={
                            <FeatureController
                                featureName="page-planning"
                                customerData={customerData}
                                loading={<Loading size="page-height" />}
                                child={
                                    <Planning
                                        buildings={buildings}
                                        customerData={customerData}
                                        selectedBuildings={selectedBuildings}
                                        setSelectedBuildings={
                                        setSelectedBuildings
                                        }
                                        selectedTags={selectedTags}
                                        setSelectedTags={setSelectedTags}
                                    />
                                }
                                authDeniedChild={<AccessDenied />}
                            />
                        }
                        authDeniedChild={<Empty />}
                    />
                    <Route
                        path="/explorer"
                        element={
                            <FeatureController
                                featureName="page-explorer"
                                customerData={customerData}
                                loading={<Loading size="page-height" />}
                                child={<Explore customerData={customerData} />}
                                authDeniedChild={<AccessDenied />}
                            />
                        }
                    />
                    <Route
                        path="/market"
                        element={
                            <FeatureController
                                featureName="page-market"
                                customerData={customerData}
                                loading={<Loading size="page-height" />}
                                child={<MarketIntelligence />}
                                authDeniedChild={<AccessDenied />}
                            />
                        }
                    />
                </Routes>
            </WorkspaceProvider>
        </Layout>
    );
};

export default App;
