import React from 'react';
import './legend.css';

export interface LegendProps {

    items: Array<string>;
    colours: Array<string>;
}

export const Legend = ({ items, colours, justify }: LegendProps) => {


    if (items.length !== colours.length) {
        console.warn("Legend component received different length arrays for colours and items.")
    }

    return (
        <ul>
            {items.map((item, index) => {
                return (
                    <li>
                        <div
                            className="legend-colour"
                            style={{ backgroundColor: colours[index] }}></div>
                        {item}
                    </li>
            )})}
        </ul>
    )
    
};
