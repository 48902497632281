import {useEffect, useState, useMemo} from "react";
import {HeatMap, Map, GoogleApiWrapper} from "google-maps-react";

import HeatmapControl, {
    defaultHeatmapColors,
} from "../../components/molecules/HeatmapControl";
import {getMetricLabel, getMetricType} from "../../global/metric";
import KPICard, {KPICardData} from "../../components/molecules/KPICard";
import {buildKPIDataMI} from "../../components/molecules/KPICard/helpers";

import MapControls from "../../components/molecules/MapControls";
import {getAllFilters} from "../../global/api";
import {queryDataForMapMI} from "../../global/api";

export const heatmapMetrics = [
    "total_measure_incremental_costs_cad_total",
    "annual_emissions_2022_tonnes_co2e_total",
    "annual_emission_savings_potential_tonnes_co2e_total",
    "number_of_buildings",
];

interface MarketIntelligenceProps {
    google: any;
}

const MarketIntelligence = ({google}: MarketIntelligenceProps) => {
    const [rawData, setRawData] = useState<any[]>([]);
    const [filters, setFilters] = useState([]);
    const [weightMetric, setWeightMetric] = useState<string>(heatmapMetrics[0]);
    const [currentMetricMinMax, setCurrentMetricMinMax] = useState<{
        min: number;
        max: number;
    }>({min: 0, max: 0});
    const [filteredKPICardBuildingData, setFilteredKPICardBuildingData] =
        useState<KPICardData | null>(null);
    const [mapReference, setMapReference] = useState<any>();

    const queryData = useMemo(() => {
        return queryDataForMapMI(setRawData, console.error);
    }, [setRawData]);

    useEffect(() => {
        getAllFilters(setFilters, console.error);
    }, []);

    // this use effect is the set the kpi card data when the map is finished loading and the building data has been fetched
    useEffect(() => {
        if (mapReference?.getBounds()) {
            setFilteredKPICardBuildingData(
                buildKPIDataMI(rawData, mapReference.getBounds())
            );
        }
    }, [rawData, mapReference]);

    const heatmapData = useMemo(() => {
        return rawData
            .filter((datum: any) => datum.latitude && datum.longitude)
            .map((datum: any) => {
                return {
                    lat: datum.latitude,
                    lng: datum.longitude,
                    weight: datum[weightMetric],
                };
            });
    }, [rawData, weightMetric]);

    useEffect(() => {
        const weights = heatmapData
            .map((d) => d.weight)
            .filter((d) => d === 0 || !!d);
        setCurrentMetricMinMax({
            min: Math.min(...weights),
            max: Math.max(...weights),
        });
    }, [heatmapData]);

    const mapOnIdle = (_, map) => {
        setFilteredKPICardBuildingData(
            buildKPIDataMI(rawData, map.getBounds())
        );
    };

    return (
        <div>
            <MapControls filters={filters} queryData={queryData} />
            {filteredKPICardBuildingData && (
                <KPICard data={filteredKPICardBuildingData} />
            )}
            {heatmapData.length !== 0 && (
                <HeatmapControl
                    onSetMetric={setWeightMetric}
                    metrics={heatmapMetrics
                        .map((metric) => {
                            return {label: getMetricLabel(metric), key: metric};
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    currentMetricMinMax={currentMetricMinMax}
                    selectedMetric={{
                        key: weightMetric,
                        label: getMetricLabel(weightMetric),
                        metricType: getMetricType(weightMetric),
                    }}
                />
            )}
            <div className="full-page-height width-100p overflow-hidden position-absolute">
                <Map
                    google={google}
                    initialCenter={{
                        lat: 49.5262,
                        lng: -101.4929,
                    }}
                    onIdle={mapOnIdle}
                    scaleControl
                    fullscreenControl={false}
                    streetViewControl={false}
                    zoomControl={false}
                    mapTypeControl={false}
                    zoom={4}
                    onReady={(_: any, map) => setMapReference(map)}
                >
                    <HeatMap
                        positions={heatmapData}
                        position={
                            heatmapData[0]
                        } /* this is a hack to get the heatmap to update when the data prop changes 
                               https://github.com/fullstackreact/google-maps-react/issues/399 */
                        opacity={0.75}
                        gradient={[
                            "rgba(0, 255, 255, 0)",
                            ...defaultHeatmapColors,
                        ]} // need to add a transparent colour at the start of the array otherwise the colours don't work properly
                        radius={ 1.3 }
                        dissipating={false}
                    />
                </Map>
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyAHjrNBzQbo3Lp2jsdgQ8e9u3GLRHhTvvo",
    libraries: ["visualization"],
})(MarketIntelligence);
