import "./kpiCallout.css"
import { Title } from "../Typography";
import InsightTooltip from "../InsightTooltip";
import { useState } from "react";

interface KPICalloutProps {
    color?: string;
    backgroundColor?: string;
    title?: string;
    subtitle?: string;
    value: string | number;
    units?: string;
    width?: string | number;
    height?: string | number;
    tooltipContent?: any;
}

interface KPITooltipProps {
    color?: string;
    visible: boolean;
    xOffset?: string | number;
    yOffset?: string | number;
    content?: any;
}

const KPITooltip = ({ visible, xOffset, yOffset, color, content }: KPITooltipProps) => {

    if (!visible) {
        return null
    }

    return (
        <InsightTooltip
            style={{
                position: "fixed",
                left: xOffset,
                top: yOffset,
                zIndex: 50,
                transition: "left 0.2s, top 0.2s",
                transitionTimingFunction: "linear",
            }}
            data={content}
            color={color} />
    )
}



const KPICallout = ({ tooltipContent, height, width, color, backgroundColor, title, subtitle, value, units }: KPICalloutProps) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [xOffset, setXOffset] = useState(null);
    const [yOffset, setYOffset] = useState(null);

    const style = { marginBottom: "var(--size-m)" }
    style["color"] = color ? color : "var(--audette-black)";
    style["backgroundColor"] = backgroundColor ? backgroundColor : "var(--audette-white)";
    style["width"] = width ? width : "var(--size-xl)";
    style["height"] = height ? height : "100%";

    const mouseMovement = (e) => {
        setShowTooltip(true);
        setXOffset(e.clientX + 10);
        setYOffset(e.clientY - 20);
    };

    return (
        <div
            style={style}
            className="aud-kpi-callout"
            onMouseMove={mouseMovement}
            onMouseLeave={() => {
                setShowTooltip(false)
            }}>
            <KPITooltip
                visible={showTooltip}
                xOffset={xOffset}
                yOffset={yOffset}
                color={backgroundColor ? backgroundColor : "var(--audette-light-teal-secondary)"}
                content={tooltipContent} />

            {title && <Title margin="0" lineHeight="1" center>{title}</Title>}
            {subtitle && <Title size="small" margin="0" lineHeight="1" center>{subtitle}</Title>}
            <Title size="large" margin="0" lineHeight="1">{value}</Title>
            {units && <Title size="small" margin="0" lineHeight="1">{units}</Title>}

        </div>
    )
}
export default KPICallout;
