import { ResponsiveSankey } from "@nivo/sankey";
import { newMeasure, newAttribute } from "@gooddata/sdk-model";
import { useBackend } from "@gooddata/sdk-ui";
import { Loading, Error, InsightTooltip } from '../../design-system';
import { useState, useEffect } from 'react';

interface Props {
    type: string;
    filters?: any;
    workspace: string;
    optional?: boolean;
    micro?: boolean;
};

const identifiers : any = {
    "energy": "fact.out_end_use_table.energy_use_kwh",
    "cost":   "fact.out_end_use_table.energy_cost_cad",
    "carbon": "fact.out_end_use_table.emissions_kg_co2",
}; 

const globalIds : any = {
    "energy": "m_fact.out_end_use_table.energy_use_kwh_sum",
    "cost":   "m_fact.out_end_use_table.energy_cost_cad_sum",
    "carbon": "m_fact.out_end_use_table.emissions_kg_co2_sum",
};

const units : any = {
    "energy": "kWh",
    "cost":   "CAD",
    "carbon": "Kg CO2",
}

const colours = [
    "var(--audette-light-teal-secondary)", // Teal
    "var(--audette-light-orange)", // Pink
    "var(--audette-purple)", // Purple
    "var(--audette-black)", // Black
    "var(--audette-hot-pink)", // Hot Pink
    "var(--audette-concrete)"  // Concrete
];

const organizeData = (data) => {

    const nodeSet = new Set();
    var links = [];

    // Get Nodes
    data.headerItems[1].forEach((titleGroup, index) => {
        titleGroup.forEach((title, idx) => {
            nodeSet.add(title.attributeHeaderItem.name);
        });
    });

    const nodes = [];
    nodeSet.forEach((node) => nodes.push({ id: node }));
    
    // Organize Data
    data.data.forEach((measureData, measureIndex) => {

        measureData.forEach((datum, dataIndex) => {
            
            links.push({
                type:      data.headerItems[0][0][measureIndex].measureHeaderItem.name,
                source:    data.headerItems[1][1][dataIndex].attributeHeaderItem.name, // Fuel Type
                target:    data.headerItems[1][0][dataIndex].attributeHeaderItem.name, // End Use
                value:     parseFloat(datum)
            });
        });
    })
    
    return {links: [...links], nodes: nodes};
};


const Sankey = ({ workspace, type, filters, optional = false, micro = false }:Props) => {

    const backend = useBackend();
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(false);

    useEffect(() => {

        setData(undefined);

        const consumption  = newMeasure(identifiers["energy"], m => m.aggregation("sum"));
        const cost         = newMeasure(identifiers["cost"],   m => m.aggregation("sum"));
        const emissions    = newMeasure(identifiers["carbon"], m => m.aggregation("sum"));
        const endUses      = newAttribute("label.out_end_use_table.end_use");
        const utilityTypes = newAttribute("label.out_end_use_table.utility");

        const getData = async () => { 
            let result;
            try {
                result = await backend
                    .workspace(workspace)
                    .execution()
                    .forItems([consumption, cost, emissions, endUses, utilityTypes], filters)
                    .execute();

                const newData = await result.readAll()
                return organizeData(newData);
            } catch (e) {
                console.error("EUS ERROR:\n", e);
                setError(true);
            }
        }

        getData().then(newData => setData(newData));

    }, [filters, backend, workspace]);        
    
    if (error) {
        if (optional) return null;
        return (
            <Error micro={micro} />
        )
    }

    if (data === undefined ) {
        if (optional) return null;
        return (
            <Loading />
        )
    }
    
    const sankeyData = {
        links: data.links.filter((link) => link.type === globalIds[type] && link.value > 0),
        nodes: data.nodes
    }
    
    return (
        <ResponsiveSankey
            data={sankeyData}
            margin={{ bottom: 40 }}
            align="justify"
            colors={colours}
            nodeOpacity={1}
            nodeHoverOthersOpacity={0.35}
            nodeThickness={18}
            nodeSpacing={24}
            nodeBorderWidth={0}
            nodeBorderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
            nodeBorderRadius={3}
            linkOpacity={0.5}
            linkHoverOthersOpacity={0.1}
            linkContract={3}
            enableLinkGradient={true}
            labelPosition="outside"
            labelOrientation="vertical"
            labelPadding={16}
            labelTextColor={{ from: "color", modifiers: [["darker", 1]] }}
            nodeTooltip={(node) => {
                return (
                    <InsightTooltip
                        color={node.node.color}
                        data={{
                            "Type": node.node.label,
                            "Amount": node.node.value.toLocaleString() + " " + units[type],
                        }} />
                )
            }}
            linkTooltip={(link) => {
                return (
                    <InsightTooltip
                        color={link.link.color}
                        data={{
                            "Utility Type": link.link.source.id,
                            "Target": link.link.target.id,
                            "Amount": (type === "cost" ? "$" : "") + link.link.value.toLocaleString() + " " + units[type]
                        }} />
                )
            }}
            legends={[
                {
                    anchor: "bottom",
                    direction: "row",
                    translateY: 20,
                    itemWidth: 100,
                    itemHeight: 14,
                    itemDirection: "right-to-left",
                    itemsSpacing: 2,
                    itemTextColor: "#999",
                    symbolSize: 14,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemTextColor: "#000"
                            }
                        }
                    ]
                }
            ]}
        />
    );
}
export default Sankey;

