import Spin from "../Spin";


export interface LoadingProps {

    size? : "page-height"
};

const Loading = ({size}:LoadingProps) => {

    let classes;
    let styles = { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' };

    if (size === "page-height") {
        classes = "full-page-height";
    } else {
        styles["height"] = "100%";
    }
    
    return (
        <div
            className={classes}
            style={styles}>
            <Spin size="large" />
        </div>
    )
    
}
export default Loading;
 
