import { ResponsiveBar } from "@nivo/bar";
import { newMeasure, newAttribute } from "@gooddata/sdk-model";
import { useBackend } from "@gooddata/sdk-ui";
import { Loading, Error, InsightTooltip } from '../../design-system';
import { useState, useEffect } from 'react';

interface Props {
    type: string;
    filters?: any;
    workspace: string;
};

const identifiers : any = {
    "energy": "fact.out_performance.eui_kwh_sq_m",
    "benchmark-energy": "fact.out_benchmarks.benchmark_eui_kwh_sq_m",
    "cost":   "fact.out_performance.cost_intensity_cad_sq_m",
    "benchmark-cost": "fact.out_benchmarks.benchmark_cost_intensity_cad_sq_m",
    "carbon": "fact.out_performance.ghgi_kg_co2e_sq_m",
    "benchmark-carbon": "fact.out_benchmarks.benchmark_ghgi_kg_co2e_sq_m",
};


const units : any = {
    "energy": "kWh / m2",
    "cost":   "CAD / m2",
    "carbon": "Kg CO2 / m2",
}

const colours = [
    "var(--audette-purple)", // Purple

    
    //"var(--audette-black)", // Black
    "var(--audette-hot-pink)", // Hot Pink
    
];

const organizeData = (data) => {
    
    const buildingData = {};

    // Build Data
    data.data.forEach((measureData, measureIndex) => {
        measureData.forEach((datum, dataIndex) =>{

            const building = data.headerItems[1][0][dataIndex].attributeHeaderItem.name;
            const measure  = convertMeasure(data.headerItems[0][0][measureIndex].measureHeaderItem.name);
            const value    = parseFloat(datum);

            if (buildingData[building] === undefined) {
                buildingData[building] = { building: building };
            }

            buildingData[building][measure] = value;   
        });
    })

    // Reformat Data
    const newData = Object.entries(buildingData).map(([building, point]) => point);

    return { buildings: buildingData, data: newData }
};

const convertMeasure = (measure) => {

    if ( measure === "m_fact.out_performance.ghgi_kg_co2e_sq_m_sum")                return "GHGI - Your Building";
    if ( measure === "m_fact.out_benchmarks.benchmark_ghgi_kg_co2e_sq_m_sum")       return "GHGI - Benchmark";
    if ( measure === "m_fact.out_performance.cost_intensity_cad_sq_m_sum")          return "Cost - Your Building";
    if ( measure === "m_fact.out_benchmarks.benchmark_cost_intensity_cad_sq_m_sum") return "Cost - Benchmark";
    if ( measure === "m_fact.out_performance.eui_kwh_sq_m_sum")                     return "EUI - Your Building";
    if ( measure === "m_fact.out_benchmarks.benchmark_eui_kwh_sq_m_sum")            return "EUI - Benchmark";

    return measure;
}



const BenchmarkBarChart = ({ workspace, type, filters }:Props) => {

    const backend = useBackend();
    const [data, setData] = useState(undefined);
    const [keys, setKeys] = useState([]);
    const [error, setError] = useState(false);
    
    useEffect(() => {

        setData(undefined);

        const energyDensity           = newMeasure(identifiers["energy"], m => m.aggregation("sum"));
        const energyBenchmarkDensity  = newMeasure(identifiers["benchmark-energy"], m => m.aggregation("sum"));
        
        const costDensity    = newMeasure(identifiers["cost"],   m => m.aggregation("sum"));
        const costBenchmarkDensity  = newMeasure(identifiers["benchmark-cost"], m => m.aggregation("sum"));
        
        const carbonDensity  = newMeasure(identifiers["carbon"], m => m.aggregation("sum"));
        const carbonBenchmarkDensity  = newMeasure(identifiers["benchmark-carbon"], m => m.aggregation("sum"));
        
        const buildingName   = newAttribute("label.out_building_information.building_name");

        const getData = async () => {

            let result;
            try {
                result = await backend
                    .workspace(workspace)
                    .execution()
                    .forItems([
                        energyDensity,
                        costDensity,
                        carbonDensity,
                        energyBenchmarkDensity,
                        costBenchmarkDensity,
                        carbonBenchmarkDensity,
                        buildingName
                    ], filters)
                    .execute();

                const newData = await result.readAll()
                return organizeData(newData);
            } catch (e) {
                console.error("EUS ERROR:\n", e);
            }
        }

        getData().then(newData => setData(newData));

    }, [filters, backend, workspace]);


    useEffect(() => {
        if (type === "carbon") {
            setKeys([
                "GHGI - Your Building",
                "GHGI - Benchmark",
            ])
        }
        if (type === "cost") {
            setKeys([
                "Cost - Your Building",
                "Cost - Benchmark",
            ])
        }
        if (type === "energy") {
            setKeys([
                "EUI - Your Building",
                "EUI - Benchmark",
            ])
        }
    }, [type])

    if (error) {
        return (
            <Error />
        )
    }

    if (data === undefined ) {
        return (
            <Loading />
        )
    }

    console.log('BBC -> data:', data);
    
    return (
            <ResponsiveBar
                data={data.data}
                motionConfig="molasses"
                keys={keys}
                indexBy="building"
                margin={{ top: 10, right: 150, bottom: 140, left: 60 }}
                padding={0.3}
                innerPadding={1}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={colours}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -90,
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={100}
                labelSkipHeight={100}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                tooltip={(e) => {
                    const prefix = e.id.split(" - ")[0];
                    const keyValues = { Building: e.indexValue };
                    keyValues["Building " + prefix] = (type === "cost" ? "$" : "") + data.buildings[e.indexValue][prefix + " - Your Building"] + " " + units[type];
                    keyValues["Benchmark " + prefix] = (type === "cost" ? "$" : "") + data.buildings[e.indexValue][prefix + " - Benchmark"] + " " + units[type];
                    return <InsightTooltip
                               color={e.color}
                               data={keyValues} /> }}
            />
        
    );
}
export default BenchmarkBarChart;

