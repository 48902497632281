import "./kpi-card.css";
import {getMetricDisplayInfo, MetricType} from "../../../global/metric";

export interface KPICardData {
    numberOfbuildings: number;
    totalEmissions: number;
    requiredFinancing: number;
    emissionSavings: number;
}

interface KPICardProps {
    data: KPICardData;
}

const displayValue = (value: number, metricType: MetricType) => {
    const {prefix, displayValue, suffix, unit} = getMetricDisplayInfo(
        value,
        metricType
    );

    return (
        <div>
            <span className="value">{`${prefix}${displayValue}`}</span>
            <span className="units">{`${suffix} ${unit}`}</span>
        </div>
    );
};

const KPICard = ({data}: KPICardProps) => {
    const {
        numberOfbuildings,
        totalEmissions,
        requiredFinancing,
        emissionSavings,
    } = data;

    return (
        <div className="floating-kpi">
            <div>
                <div className="title">Buildings</div>
                <div>
                    <span className="value">
                        {numberOfbuildings.toLocaleString()}
                    </span>
                </div>
            </div>
            <div>
                <div className="title">Total Emissions</div>
                {displayValue(totalEmissions, MetricType.AnnualCO2e)}
            </div>
            <div>
                <div className="title">Emission Savings Identified</div>
                {displayValue(emissionSavings, MetricType.AnnualCO2e)}
            </div>
            <div>
                <div className="title">Required Financing</div>
                {displayValue(requiredFinancing, MetricType.DollarCAD)}
            </div>
        </div>
    );
};

export default KPICard;
