const getSmartValue = (count: number, type: string) => {
    const unitLookup = {
        "annual carbon": [
            "Kg CO2e / yr",
            "Tonnes CO2e / yr",
            "KT CO2e / yr",
            "MT CO2e / yr",
            "GT CO2e / yr",
        ],
        money: ["", "Thousand", "Million", "Billion", "Trillion"],
    };
    const prefixLookup = {
        money: "$",
    };

    const units = unitLookup[type];
    const prefix = prefixLookup[type];

    let mantissa = count;
    let unitOrder = 0;

    while (mantissa >= 1000 && unitOrder < units.length) {
        mantissa = mantissa / 1000;
        unitOrder += 1;
    }

    return (
        (prefix ? prefix : "") +
        mantissa.toFixed(2).toLocaleString() +
        " " +
        units[unitOrder]
    );
};

export {getSmartValue};
