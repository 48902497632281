import { WarningTwoTone } from "@ant-design/icons";

interface Props {
    errorCode?: string;
    micro?: boolean;
}

const errors : any = {
    "100A": ["Error", "If this error persists, please contact support at support@audette.io"],
    "101B": ["Data Error", "Please contact support at support@audette.io"]
}


const Error = ({ errorCode, micro = false }:Props) => {

    var error = errors["100A"];
    if (errorCode !== undefined)
        error = errors[errorCode];
    
    if (error === undefined) {
        errorCode = "100";
        error = errors["100A"];
    }
        
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <WarningTwoTone style={{ fontSize: '48px'}} twoToneColor="#eb03ad"/>
            {!micro &&
             <>
               <div><b>{error[0]}: </b>{error[1]}</div>
               {errorCode !== undefined &&
                 <div><b>Error Code: </b>{errorCode}</div>
               }
             </>
            }
        </div>
    )
    
}
export default Error;
 
