import "@gooddata/sdk-ui-dashboard/styles/css/main.css";
import { Loading } from "../../design-system";
import { useState, useEffect } from 'react';

import PlanningOverview from './PlanningOverview';
import BuildingDetail from '../../components/organisms/BuildingDetail';



interface Props {
    buildings:Array<any>;
    customerData:any;
    selectedBuildings: Array<string>;
    setSelectedBuildings: Function;
    selectedTags: Array<string>;
    setSelectedTags: Function;
};

const Planning = ({buildings, customerData, selectedBuildings, setSelectedBuildings, selectedTags, setSelectedTags}:Props) => {

    const [showBuildingDetail, setShowBuildingDetail] = useState(false);
    useEffect(() => {
        setShowBuildingDetail(selectedBuildings.length === 1);
    }, [selectedBuildings]);

    if (buildings === undefined) {
        return (<Loading />) 
    }

    return (
        <div className="flex-row flex-stretch flex-align-stretch">
            <div className="flex-resize">
                <PlanningOverview
                    selectedBuildings={selectedBuildings}
                    setSelectedBuildings={setSelectedBuildings}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    buildings={buildings}
                    workspace={customerData.workspace_uid}
                />
            </div>
            <BuildingDetail
                building={buildings.filter((bldg:any) => bldg.name === selectedBuildings[0])[0]}
                show={showBuildingDetail} />
        </div>
    );
}

export default Planning;
