import {KPICardData} from "./index";

// should work for international coordinates too (where longitude is ±180°)
const pointIsWithinMapBounds = (
    point: {lat: number; long: number},
    mapBounds: any
): boolean => {
    const sw = mapBounds.getSouthWest();
    const ne = mapBounds.getNorthEast();

    const leftLongitude = sw.lng();
    const rightLongitude = ne.lng();
    const mapIncludes180thMeridian = leftLongitude > rightLongitude;

    const insideLeftBound = leftLongitude < point.long;
    const insideRightBound = point.long < rightLongitude;

    let withinLongitudeBounds;
    if (mapIncludes180thMeridian) {
        withinLongitudeBounds = insideLeftBound || insideRightBound;
    } else {
        withinLongitudeBounds = insideLeftBound && insideRightBound;
    }

    const withinLatitudeBounds = sw.lat() < point.lat && point.lat < ne.lat();
    return withinLatitudeBounds && withinLongitudeBounds;
};

export const buildKPIDataExplore = (
    data: any[],
    mapBounds: any
): KPICardData => {
    let totalEmissions = 0;
    let requiredFinancing = 0;
    let emissionSavings = 0;

    const dataWithinViewport = data.filter((buildingData) =>
        pointIsWithinMapBounds(
            {lat: buildingData.latitude, long: buildingData.longitude},
            mapBounds
        )
    );

    dataWithinViewport.forEach((buildingData) => {
        const buildingSavings =
            buildingData.annual_emission_savings_potential_tonnes_co2e;
        const buildingEmmissions =
            buildingData.annual_emissions_2022_tonnes_co2e;
        const buildingFinancing =
            buildingData.total_measure_incremental_costs_cad;

        // when values are smaller than zero for emission savings, we treat them as zero in this calculation
        if (buildingSavings !== undefined && buildingSavings > 0)
            emissionSavings += buildingSavings;
        if (buildingEmmissions !== undefined)
            totalEmissions += buildingEmmissions;
        if (buildingFinancing !== undefined)
            requiredFinancing += buildingFinancing;
    });

    return {
        numberOfbuildings: dataWithinViewport.length,
        totalEmissions,
        requiredFinancing,
        emissionSavings,
    };
};

export const buildKPIDataMI = (data: any[], mapBounds: any): KPICardData => {
    let totalEmissions = 0;
    let requiredFinancing = 0;
    let emissionSavings = 0;
    let numberOfbuildings = 0;

    const dataWithinViewport = data.filter((buildingData) =>
        pointIsWithinMapBounds(
            {lat: buildingData.latitude, long: buildingData.longitude},
            mapBounds
        )
    );

    dataWithinViewport.forEach((datum) => {
        const buildingSavings =
            datum.annual_emission_savings_potential_tonnes_co2e_total;
        const buildingEmmissions =
            datum.annual_emissions_2022_tonnes_co2e_total;
        const buildingFinancing =
            datum.total_measure_incremental_costs_cad_total;
        const buildings = datum.number_of_buildings;

        // when values are smaller than zero for emission savings, we treat them as zero in this calculation
        if (buildingSavings !== undefined && buildingSavings > 0)
            emissionSavings += buildingSavings;
        if (buildingEmmissions !== undefined)
            totalEmissions += buildingEmmissions;
        if (buildingFinancing !== undefined)
            requiredFinancing += buildingFinancing;
        if (buildings !== undefined) numberOfbuildings += buildings;
    });

    return {
        numberOfbuildings,
        totalEmissions,
        requiredFinancing,
        emissionSavings,
    };
};
