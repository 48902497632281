import React from 'react';
import { Card as CardAD } from 'antd';
import './card.css';

export interface CardProps {
    title: string;
    padded?: boolean;
    child: JSX.Element;
}


export const Card = ({ title, padded = false, child }: CardProps) => (
    <CardAD title={title} >
        {child}
    </CardAD>
);
