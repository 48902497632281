import {Select, Switch, Tag, Button} from "antd";
import {useEffect, useState} from "react";

const {Option} = Select;

interface MapControlsProps {
    filters: any;
    queryData: (requestBody: Object) => void;
    hidden?: boolean;
    setShowBuildingFootprints?: (show: boolean) => void;
    showBuildingFootprints?: boolean;
}

const MapControls = ({
    filters,
    hidden,
    queryData,
    showBuildingFootprints,
    setShowBuildingFootprints,
}: MapControlsProps) => {
    const [selectedSearchOptions, setselectedSearchOptions] = useState<
        string[]
    >([]);

    const renderSearchOptions = () => {
        let options: any = [];
        for (let i = 0; i < filters.length; i++) {
            let filter = filters[i];
            for (let j = 0; j < filter["options"].length; j++) {
                let label: string = filter["label"];
                let relationship: string = filter["relationship"];
                let value: string = filter["options"][j];

                let name = {
                    BELONGS_TO: `Belongs: ${value}`,
                    IS_LOCATED_IN: `Located: ${value} (${label})`,
                    IS_TYPE: `Type: ${value}`,
                }[relationship];

                options.push(
                    <Option
                        key={label + ":" + relationship + ":" + value}
                        value={label + ":" + relationship + ":" + value}
                    >
                        {name}
                    </Option>
                );
            }
        }
        return options;
    };

    const tagRenderer = ({label, value, onClose}: any) => {
        let relationship: string = value.split(":")[1];
        let color = {
            BELONGS_TO: "var(--audette-purple)",
            IS_LOCATED_IN: "var(--audette-hot-pink)",
            IS_TYPE: "var(--audette-black)",
        }[relationship];

        return (
            <Tag closable color={color} onClose={onClose}>
                {label}
            </Tag>
        );
    };

    useEffect(() => {
        let body = {
            filters: [] as any,
        };
        for (let i = 0; i < selectedSearchOptions.length; i++) {
            let filter = selectedSearchOptions[i].split(":");
            body["filters"].push({
                label: filter[0],
                relationship: filter[1],
                value: filter[2],
            });
        }
        queryData(body);
    }, [selectedSearchOptions]);

    return (
        <div
            style={
                hidden
                    ? {display: "none"}
                    : {
                          display: "flex",
                          alignItems: "center",
                          position: "fixed",
                          top: 80,
                          left: 20,
                          zIndex: 1000,
                          width: "100%",
                      }
            }
        >
            <div
                style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 430,
                }}
            >
                <Select
                    listHeight={450}
                    mode="multiple"
                    onChange={setselectedSearchOptions}
                    placeholder="Search"
                    showSearch
                    size="large"
                    tagRender={(props) => tagRenderer(props)}
                    value={selectedSearchOptions}
                    style={{width: "100%"}}
                >
                    {renderSearchOptions()}
                </Select>
                <Button
                    style={{color: "var(--audette-hot-pink)"}}
                    onClick={() => {
                        setselectedSearchOptions([]);
                    }}
                    type="link"
                >
                    Clear
                </Button>
            </div>
            {showBuildingFootprints !== undefined &&
                setShowBuildingFootprints !== undefined && (
                    <Switch
                        checked={showBuildingFootprints}
                        checkedChildren={"Show Building Footprints"}
                        unCheckedChildren={"Show Building Footprints"}
                        onChange={setShowBuildingFootprints}
                    />
                )}
        </div>
    );
};

export default MapControls;
