import {
    BRICK_BACKEND_DOMAIN,
    AUTH_DOMAIN,
    EXPLORE_API_DOMAIN,
} from "./constants";

/* Contract:
 *
 * All the external functions in this file are part of the export, and are designed to be used throughout
 * the rest of this project. They map one to one to an endpoint on the backend.
 *
 * Each external function has it's last argument be a failure Callback, and it's second to last argument as
 * a success callback. If an api call is successful, any data that is returned (beyond a success message)
 * are passed to the success callback. On failure, the failure callback will be called without any
 * arguments.
 */

/***********************************
 *      INTERNAL FUNCTIONS
 ***********************************/

const domains = {
    AUTH_API: AUTH_DOMAIN,
    BRICK_API: BRICK_BACKEND_DOMAIN,
    EXPLORE_API: EXPLORE_API_DOMAIN,
};

const getPostOptions = (body: any) => {
    return {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };
};

const getGetOptions = () => {
    return {
        method: "GET",
        mode: "cors",
    };
};

const makeRequest = (
    host: string,
    endpoint: string,
    options: any,
    successCallback: any,
    failureCallback: any
) => {
    const domain = domains[host];

    fetch(domain + endpoint, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            console.log("response to " + endpoint + ":", data);
            if (data.status === "success") {
                if (successCallback !== null && successCallback !== undefined)
                    successCallback(data.data);
            } else {
                console.log("failure:", data);
                failureCallback();
            }
        })
        .catch((error) => {
            if (failureCallback !== null && failureCallback !== undefined)
                failureCallback();
            console.log("Error in request to " + endpoint + ":", error);
        });
};

// Cache a request
const setUpFeatureFlagCachedRequest = (host: any, options: any) => {
    var cache: any = null;
    return (
        customer_uid: string,
        successCallback: any,
        failureCallback: any
    ) => {
        if (cache === null) {
            makeRequest(
                host,
                "/meta/feature-flags/" + customer_uid,
                options,
                (data: any) => {
                    cache = data;
                    if (
                        successCallback !== null &&
                        successCallback !== undefined
                    )
                        successCallback(data);
                },
                failureCallback
            );
        } else {
            successCallback(cache);
        }
    };
};

/***********************************
 *      EXTERNAL FUNCTIONS
 ***********************************/

const getBuildings = (
    customer_uid: string,
    successCallback: any,
    failureCallback: any
) => {
    console.log("calling getBuildings():", customer_uid);
    makeRequest(
        "BRICK_API",
        "/building/" + customer_uid,
        getGetOptions(),
        successCallback,
        failureCallback
    );
};

const getCustomerData = (
    email: string,
    successCallback: any,
    failureCallback: any
) => {
    const params = new URLSearchParams({email: email}).toString();
    console.log("calling getCustomerData():", params);
    makeRequest(
        "AUTH_API",
        "/user?" + params,
        getGetOptions(),
        successCallback,
        failureCallback
    );
};

const getBuildingNameFromAddress = (
    address: string,
    successCallback: any,
    failureCallback: any
) => {
    const params = new URLSearchParams({address: address}).toString();
    console.log("calling getBuildingNameFromAddress():", params);
    makeRequest(
        "BRICK_API",
        "/building/lookup_address?" + params,
        getGetOptions(),
        successCallback,
        failureCallback
    );
};

const getFeatureFlaggingConfig = setUpFeatureFlagCachedRequest(
    "BRICK_API",
    getGetOptions()
);

const getAllFilters = (successCallback, failureCallback) => {
    makeRequest(
        "EXPLORE_API",
        "/filters",
        getGetOptions(),
        successCallback,
        failureCallback
    );
};

const queryDataForMap = (successCallback, failureCallback) => {
    return (body: Object) => {
        makeRequest(
            "EXPLORE_API",
            "/query",
            getPostOptions(body),
            successCallback,
            failureCallback
        );
    };
};

const queryDataForMapMI = (successCallback, failureCallback) => {
    return (body: Object) => {
        makeRequest(
            "EXPLORE_API",
            "/query-mi",
            getPostOptions(body),
            successCallback,
            failureCallback
        );
    };
};

export {
    getFeatureFlaggingConfig,
    getCustomerData,
    getBuildings,
    getAllFilters,
    queryDataForMap,
    queryDataForMapMI,
    getBuildingNameFromAddress,
};
