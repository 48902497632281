import { LoadingOutlined } from '@ant-design/icons';

interface SpinProps {
    size: "small" | "default" | "large" | undefined;
    color?: string;
}

const AudetteSpin = ({size, color}:SpinProps) => {

    if (size === undefined) {
        size = "default";
    }
    
    if (color === undefined) {
        color = "var(--audette-hot-pink)";
    }

    const sizes = {
        "small": 24,
        "default": 48,
        "large": 96
    }

    return <LoadingOutlined style={{ fontSize: sizes[size], color: color }}/>
    
}
export default AudetteSpin;
 
