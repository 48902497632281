import React from 'react';
import 'antd/dist/antd.css';
import './logo.css';

import BlackLogo from '../../../assets/images/logo-black.svg';
import GrayLogo from '../../../assets/images/logo-gray.svg';
import PinkLogo from '../../../assets/images/logo-pink.svg';
import WhiteLogo from '../../../assets/images/logo-white.svg';

export interface LogoProps {
    /**
     * What color should the logo be?
     */
    color: 'black' | 'gray' | 'pink' | 'white';
}

/**
 * Primary UI component for user interaction
 */
export const Logo = ({
  color
}: LogoProps) => {
  
    const logos = {
        white: WhiteLogo,
        pink: PinkLogo,
        gray: GrayLogo,
        black: BlackLogo
    }

    return (
        <img className='audette-logo' alt="Audette's logo" src={ logos[color] } />
    );
};
