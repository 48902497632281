import React from 'react';
import { Card as CardAD } from 'antd';
import { Tag } from '../../atoms/Tag';
import './card.css';

export interface CardProps {


    /**
     * Name of the building
     */
    building?: string;
    /**
     * Square footage of the building in square meters
     */
    areaInSquareMeters?: string;
    /**
     * Square footage of the building in square feet
     */
    areaInSquareFeet?: string;

    customer?: string;

    /**
     * The building's year built
     */
    yearBuilt?: string;
    /**
     * The building's address
     */
    address?: string;
    /**
     * The city the building is located in
     */
    city?: string;
    /**
     * The country the building is located in
     */
    country?: string;
    /**
     * An array of facility type tags for this building
     */
    buildingType?: Array<string>;
    /**
     * Short description of applicable carbon reduction mandate
     */
    carbonMandate?: string;
}

export const Card = ({building, customer, areaInSquareMeters, areaInSquareFeet, yearBuilt, address, city,  country, buildingType = [], carbonMandate}: CardProps) => (
    <CardAD title="Building Details" >

        {building &&
         <>
             <b>Building Name</b>
             <p>{ building }</p>
         </>}

        {areaInSquareMeters &&
         <>
             <b>Gross Floor Area</b>
             <p>{ areaInSquareMeters } m<sup>2</sup> ({ areaInSquareFeet } ft<sup>2</sup>)</p>
         </>}

        {customer &&
         <>
             <b>Owner</b>
             <p>{ customer }</p>
         </>}

        {yearBuilt &&
         <>
             <b>Year of Construction</b>
             <p>{ yearBuilt }</p>
         </>
        }

        {address &&
         <>
             <b>Address</b>
             <p style={{ marginBottom: '0px' }}>{ address }</p>
             <p style={{ marginBottom: '0px' }}>{ city }</p>
             <p>{ country }</p>
         </>}

        {carbonMandate &&
        <>
            <b>Relevant Carbon Mandates</b>
            <p>{ carbonMandate }</p>
        </>}

        {buildingType &&
         <>
             <b>Building Type</b>
             <div className="comparision-tag-group">
                 { buildingType.map((type, index) =>
                     <Tag key={index} closable={false}>{type}</Tag>)
                 }
             </div>
         </>}

    </CardAD>
);
