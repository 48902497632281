import { useState, useEffect } from 'react';
import { ResponsiveStream } from "@nivo/stream";
import { Loading, Error, InsightTooltip } from '../../design-system';
import { newMeasure, newAttribute } from "@gooddata/sdk-model";
import { useBackend } from "@gooddata/sdk-ui";
import {format} from "d3-format";

interface Props {
    type: string;
    filters?: any;
    workspace: string;
    micro?: boolean;
};

const categoricalColours = [
    "var(--audette-hot-pink)",
    "var(--audette-teal)", 
    "var(--audette-orange)",
    "var(--audette-purple)",
    "var(--audette-blue)",
    "var(--audette-carrot-orange)",
    "var(--audette-green)",

    "var(--audette-hot-pink-secondary)",
    "var(--audette-teal-secondary)",
    "var(--audette-orange-secondary)",
    "var(--audette-purple-secondary)",
    "var(--audette-blue-secondary)",
    "var(--audette-carrot-orange-secondary)",
    "var(--audette-green-secondary)",

    "var(--audette-light-pink)",
    "var(--audette-light-teal)",
    "var(--audette-light-orange)",
    "var(--audette-light-purple)",
    "var(--audette-light-blue)",
    "var(--audette-light-carrot-orange)",
];

const remainingEmissionsColour = "var(--audette-concrete)";

const convertMeasureName = (name) => {

    if (["z_", "x_", "a_"].includes(name.slice(0,2))) {
        return name.slice(2);
    }
    return name;
}

// makes sure that the remaining emissions colour always matches up with the "Remaining Emissions" measure
const buildColors = (measures: string[]) => {
    // we want the chart to start with brighter colours (more aesthetic) so we start with the last index first
    let categoricalColourIndex = measures.length - 2; // -2 because we exlude the remaining emissions in this calculation

    return measures.map((measure) => {
        if (measure === "Remaining Emissions") {
            return remainingEmissionsColour; 
        }
        const colour = categoricalColours[categoricalColourIndex % categoricalColours.length];
        categoricalColourIndex--;
        return colour;
    });
}

const organizeData = (data) => {

    const measureFirstYear = {};
    const points = [];
    
    // Collect Data Points
    data.data.forEach((measureData, measureIndex) => {
        measureData.forEach((datum, dataIndex) =>{

            const measureName = convertMeasureName(data.headerItems[1][0][dataIndex].attributeHeaderItem.name);
            const year = parseInt(data.headerItems[1][1][dataIndex].attributeHeaderItem.name);
            
            points.push({
                year:      year,
                measure:   measureName,
                value:     parseFloat(datum)
            });

            if (parseFloat(datum) > 0 && measureFirstYear[measureName] === undefined) {
                measureFirstYear[measureName] = year
            }
        });
    })

    const groupedPoints = {}
    points.forEach((point) => {

        if (groupedPoints[point.year] === undefined) {
            groupedPoints[point.year] = {}
        }
        groupedPoints[point.year][point.measure] = point.value;
        
    });
        
    const measures = Object.entries(measureFirstYear)
                           .sort((a, b) => {
                               if (a[0] === "Remaining Emissions" || b[0] === "Electricity Grid Decarbonization")
                                   return -1;
                               if (b[0] === "Remaining Emissions" || a[0] === "Electricity Grid Decarbonization")
                                   return 1;
                               return a[1] - b[1];
                           })
                           .map(([measure, year]) => measure);
    
    const formattedPoints = Object.entries(groupedPoints).map(([year, data]) => data);
    return {points: formattedPoints, measures: measures};
};


const DecarbonizationRoadmap = ({ workspace, type, filters, micro=false }:Props) => {

    const backend = useBackend();
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(false);

    useEffect(() => {

        setData(undefined);

        const savings  = newMeasure("fact.out_carbon_reduction_measure_savings.total_emissions_savings_tonnes_co2e", m => m.aggregation("sum"));
        const measureNames = newAttribute("label.out_carbon_reduction_measure_savings.measure_name");
        const years        = newAttribute("datemonth.year.default");
        
        const getData = async () => { 
            let result;
            try {
                result = await backend
                    .workspace(workspace)
                    .execution()
                    .forItems([savings, measureNames, years], filters)
                    .execute();

                const newData = await result.readAll()
                return organizeData(newData);
            } catch (e) {
                console.error("Decarbonization Roadmap Error:\n", e);
            }
        }

        getData().then(newData => {
            setData(newData);
        });
        

    }, [filters, backend, workspace]);        

    if (error) {
        return (
            <Error />
        )
    }

    if (data === undefined ) {
        return (
            <Loading />
        )
    }

    if (!micro) {
        return (
            <ResponsiveStream
                data={data.points}
                keys={data.measures}
                margin={{ top: 10, right: 250, bottom: 50, left: 60 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    format: (i) => "'" + (i + 22),
                    tickSize: 3,
                    tickPadding: 5,
                    legend: 'Year',
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    orient: 'left',
                    format: (num) => format(".2s")(num),
                    tickSize: 3,
                    tickPadding: 5,
                    legend: 'Emissions Remaining (Tonnes CO2e)',
                    legendPosition: 'middle',
                    legendOffset: -50,
                }}

                enableStackTooltip={false}
                tooltip={(e) => {
                    return (
                        <InsightTooltip 
                            color={e.layer.color} 
                            data={{
                                "Measure": e.layer.id,
                                "Implementation Year": e.layer.data.indexOf(e.layer.data.find((p) => { return p.value > 0 })) + 2022
                            }} />
                    )
                }}
            
                gridYValues={0.5}
                enableGridX={true}
                enableGridY={true}
                curve="basis"
                offsetType="none"
                colors={buildColors(data.measures)}
                fillOpacity={0.85}
                borderColor={{ theme: 'background' }}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateX: 100,
                        itemWidth: 80,
                        itemHeight: 20,
                        itemTextColor: '#999999',
                        symbolSize: 12,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000000'
                                }
                            }
                        ]
                    }
                ]}
            />
        );
    } else { // micro
        return (
            <ResponsiveStream
                data={data.points}
                keys={data.measures}
                margin={{ top: 10, right: 20, bottom: 20, left: 40 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    format: (i) => {return i === 0 || i === 28 ?  i + 2022 : ""},
                    tickSize: 3,
                    tickPadding: 5,
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    orient: 'left',
                    format: (num) => format(".2s")(num),
                    tickSize: 3,
                    tickPadding: 5,
                    legendPosition: 'middle',
                    legendOffset: -50,
                }}

                enableStackTooltip={false}
                tooltip={(e) => {
                    return (
                        <InsightTooltip 
                            color={e.layer.color} 
                            data={{
                                "Measure": e.layer.id,
                                "Implementation Year": e.layer.data.indexOf(e.layer.data.find((p) => { return p.value > 0 })) + 2022
                            }} />
                    )
                }}
            
                gridYValues={0.5}
                enableGridX={true}
                enableGridY={true}
                curve="basis"
                offsetType="none"
                colors={buildColors(data.measures)}
                fillOpacity={0.85}
                borderColor={{ theme: 'background' }}
            />
        );
    }
}
export default DecarbonizationRoadmap;
