import React from 'react';
import { Radio } from 'antd';
import "./radioButton.css";

export interface OptionsObjs {
    label: string,
    value: string,
}

export interface Props {
    options: Array<OptionsObjs>;
    value: any;
    onClick?: (event:any) => void;
};

export const RadioButton = ({ options, value, onClick }: Props) => (
    <Radio.Group
        options={ options }
        optionType="button"
        value={ value }
        onChange={onClick}
    />
);
