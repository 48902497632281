import { Layout } from "antd";
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { BackendProvider } from "@gooddata/sdk-ui";

import App from './App';
import { backend } from './context/good-data';
import AuthProvider from './auth/AuthProvider';

import "./index.css";
import "@gooddata/sdk-ui-ext/styles/css/main.css";
import "@gooddata/sdk-ui-geo/styles/css/main.css";

ReactDOM.render(
    <BrowserRouter>
        <AuthProvider>
            <BackendProvider backend={ backend }>
                <Layout>
                    <App />
                </Layout>
            </BackendProvider>
        </AuthProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
