export enum MetricType {
    DollarCAD = "CAD",
    AnnualCO2e = "CO2e / y",
}

export const getMetricLabel = (metric: string): string => {
    if (metric === "annual_emissions_2022_tonnes_co2e_total")
        return "Total Annual Emissions";
    if (metric === "total_measure_incremental_costs_cad_total")
        return "Total Required Financing";
    if (metric === "annual_emission_savings_potential_tonnes_co2e_total")
        return "Total Annual Emission Savings Potential";
    if (metric === "number_of_buildings") return "Total Number of Buildings";
    return metric;
};

export const getMetricType = (metric: string): MetricType | null => {
    if (metric.includes("co2e") && metric.includes("annual"))
        return MetricType.AnnualCO2e;
    if (metric.includes("cad")) return MetricType.DollarCAD;
    return null;
};

const getSuffixes = (metricType: MetricType): string[] => {
    switch (metricType) {
        case MetricType.AnnualCO2e:
            return ["Tonnes", "KT", "MT", "GT"];
        case MetricType.DollarCAD:
            return ["", "Thousand", "Million", "Billion", "Trillion"];
        default:
            return [];
    }
};

const getMetricPrefix = (metric: MetricType | null): string => {
    switch (metric) {
        case MetricType.DollarCAD:
            return "$";
        default:
            return "";
    }
};

export interface MetricDisplayInfo {
    displayValue: string; // the value to be displayed e.g) total amount of dollars
    prefix: string; // e.g) $
    suffix: string; // e.g) Billion
    unit: string; // e.g) CO2e
}

// This function returns all the information you might need to display a metric
export const getMetricDisplayInfo = (
    value: number,
    metricType: MetricType
): MetricDisplayInfo => {
    const suffixes = getSuffixes(metricType);

    let mantissa = value;
    let suffixIndex = 0;

    while (mantissa >= 1000 && suffixIndex < suffixes.length) {
        mantissa = mantissa / 1000;
        suffixIndex += 1;
    }
    return {
        displayValue: mantissa.toFixed(2).toLocaleString(),
        prefix: getMetricPrefix(metricType),
        suffix: suffixes[suffixIndex],
        unit: metricType,
    };
};
