import { useState } from 'react';
import { RadioButton, Title } from "../../design-system";


interface OptionsObjs {
    label: string,
    value: string,
    inDrawer?: boolean,
}

interface Props {
    render: (data:any) => JSX.Element | null,
    options: OptionsObjs[];
    onClick?: (event:any) => void;
    title?: string | {[key: string]: string};
}


const getTitle = (title, option) => {

    if (title === undefined) {
        return null
    }

    if (typeof title === 'string') {
        return title
    }

    return title[option];
}


// render takes a function with one argument (the selected value) and returns a 
const RadioControlledInsight = ({ options, render, onClick, title, inDrawer = false }: Props) => {

    const [radioValue, setRadioValue] = useState(options[0].value);

    const newOnClick = (event:any) => {
        setRadioValue(event.target.value);
        if (onClick !== undefined) {
            onClick(event);
        }
    }

    if (inDrawer) {

        return (
            <div>
                <div className="flex-row space-between margin-bottom-s">
                    <Title>{getTitle(title, radioValue)}</Title>
                    <RadioButton options={options} value={radioValue} onClick={newOnClick} />
                </div>
                <div className="drawer-insight">
                      {render(radioValue)}
                </div>
            </div>
        );

    }
    
    return (
        <div className="insight">
            <div className="flex-row space-between margin-bottom-s">
                <Title>{getTitle(title, radioValue)}</Title>
                <RadioButton options={options} value={radioValue} onClick={newOnClick} />
            </div>
            {render(radioValue)}
        </div>
    );
}

export default RadioControlledInsight;
