import React from 'react';
import { Typography as TypographyAD } from 'antd';
import 'antd/dist/antd.css';
import './typography.css';

export interface TypographyProps {

    children?: React.ReactNode;
    size?: "small" | "medium" | "large";
    color?: string;
    margin?: string | number
    lineHeight?: string | number;
    center?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const Title = ({
    size = "medium",
    color = 'black',
    lineHeight,
    margin,
    children,
    center = false,
}: TypographyProps) => {

    const style = { color: color };
    if (lineHeight) style["lineHeight"] = lineHeight;
    if (margin) style["margin"] = margin;
    if (center) style["textAlign"] = "center";
    
    return (
        <TypographyAD.Title className={`audette-title-${size}`} style={style}>{ children }</TypographyAD.Title>
    );
};

export const Text = ({
    color = 'black',
    children,
    lineHeight,
    margin,
    size = 'medium',
    center = false,
}: TypographyProps) => {

    const style = { color: color };
    if (lineHeight) style["lineHeight"] = lineHeight;
    if (margin) style["margin"] = margin;

    return (
        <TypographyAD.Text className={`audette-text-${size}`} style={style}>{ children }</TypographyAD.Text>
    );
};

