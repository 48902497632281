import { InsightView } from "@gooddata/sdk-ui-ext";
import { Loading, Error, Title } from '../../design-system';

interface Props {
    insight: string,
    config?: any,
    filters?: any,
    title?: string
}

const ChartIdMap: any = {

    MapBox: 'ab5XDibYbI9R',
    CarbonRoadmap: 'aaGYCiCZ9qUY',
    ConsumptionMonitoringAllSources: "aaYYm1lqT8gO",
    ConsumptionMonitoringElectricity: "ab2tsb8XjtEl",
    ConsumptionMonitoringNaturalGas: "abPtx3rowLQi",
    CarbonBenchmarks: "ac4XPQSzCgu9",
    EnergyBenchmarks: "aboXY7LpCFbA",
    CostBenchmarks: "acAXTdrjhIxk",

    CarbonReductionMeasures: "aawFxhUN2iky",
    "Heating Plants": "aaovzj5QWiqM",
    "Cooling Plants": "aa3zrEvQyqCC",
    "Ventilation Systems": "abNzhkQrrwEx",
    "DHW Systems": "abHzxrBuZiww",
    "Envelope": "achzu7sl4llV",
    "Lighting": "ab2zzHRtbtDS",
    "Cooling Tower": "aaBzTqySqRfI",
    "Pumps": "abdzOdd2HlbX",
}

const mapboxToken = 'pk.eyJ1IjoiYXVkZXR0ZWFkbWluaXN0cmF0b3IiLCJhIjoiY2wwMDYzNTVqMDJidDNxcnM1bXhqZXBvNSJ9.J3XIPMx53WgsljeovxOQIQ';

const GoodDataInsight = ({ title, config, insight, filters }: Props) => {

    if (insight === "MapBox") {
        if (config !== undefined) {
            config['mapboxToken'] = mapboxToken
        } else {
            config = { mapboxToken: mapboxToken }
        }
    }

    return (
        <>
            {title && <Title>{title}</Title>}
            <InsightView
                LoadingComponent={() => <Loading />}
                ErrorComponent={() => <Error errorCode="100A" />}
                insight={ChartIdMap[insight]}
                config={config}
                filters={filters}
            />
        </>
    );
}

export default GoodDataInsight;
