import { factory } from "@gooddata/api-client-bear";
import { DOMAIN, GD_DOMAIN, ENV } from "./constants";


const login = async (backend: any, setAuthenticated: any) => {

    const isAuthenticated = await backend?.isAuthenticated();
    console.log('Authenticating using account:', isAuthenticated);
        
    if (isAuthenticated) {        
        setAuthenticated(true);
    } else {
        
        if (ENV === 'development') {

            const userEmail = process.env.REACT_APP_USER_EMAIL;
            const userPassword = process.env.REACT_APP_USER_PASSWORD;

            if (!userEmail || !userPassword) {
                console.log("Reminder: set the user email and password for the dev environment in the root .env (see README)");
            }

            console.log(GD_DOMAIN);
            
            const authenticateWithGoodData = () => {
                const bearClient = factory({ domain: GD_DOMAIN });
                (async () => {
                    try {
                        await bearClient.user.login(
                            userEmail || '',
                            userPassword || '');
                    } catch (error:any) {
                        console.log('development environment auth error:', error);
                    }
                })();
            }

            await authenticateWithGoodData();
            setAuthenticated(true);

        } else {
            backend?.sdk.user 
                    .initiateSamlSso(DOMAIN)
                    .catch((error: any) => {
                        console.error(error);
                    });
        }
    }
}


const logout = async (auth0Logout: any, backend: any) => {

    await backend?.sdk.user.logout();
    auth0Logout({ returnTo: DOMAIN });
    
}


export {
    login,
    logout
}
