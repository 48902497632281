import React from 'react';
import { Row, Col, Select } from 'antd';
import './customer-search.css';
import { Tag } from '../../../design-system';
import { useState, useEffect } from 'react';

const { Option, OptGroup } = Select; 

export interface CustomerSearchProps {
    
    extra?: string;

    buildings: Array<any>;
    selectedBuildings: Array<string>;
    setSelectedBuildings: Function;
    selectedTags: Array<string>;
    setSelectedTags: Function;
    onClear?: (() => void);
}

const createGroups = (buildings:Array<any>) => {
    
    let groupTree : {[key: string]: any} = {};
    buildings.forEach((building, index) => {
        Object.entries(building).forEach(([groupBy, groupValue]:Array<any>) => {
            if (groupTree[groupBy] === undefined) {
                groupTree[groupBy] = {}
            }

            if (groupTree[groupBy][groupValue] === undefined) {
                groupTree[groupBy][groupValue] = 1;
            } else {
                groupTree[groupBy][groupValue] = groupTree[groupBy][groupValue] + 1;
            }
        })
    });

    let newGroups:Array<any> = [];
    Object.entries(groupTree).forEach(([groupBy, groupValues]) => {

        Object.entries(groupValues).forEach(([groupValue, count]) => {
            if (["city", "region", "country", "facility_type", "climate_zone"].includes(groupBy)) {
                newGroups.push({ field: groupBy, value: groupValue });
            }
        });
    });

    return newGroups;
}


const buildSelectedBuildings = (buildings:Array<any>, selectedOptions:Array<any>) => {

    const selectedBuildings:Array<any> = [];
    selectedOptions.forEach((option, index) => {

        if (option.value.split("_")[0] === "grp") { // group
            const field = option['data-field'];
            const value = option['data-value'];

            buildings.forEach((building) => {
                if (building[field] === value && !selectedBuildings.includes(building.name)) {
                    selectedBuildings.push(building.name);
                }
            });
        } else { // building
            const name = option["data-name"];
            selectedBuildings.push(name);
        }
    });
    return selectedBuildings;
};



const CustomerSearch = ({ extra, buildings, selectedBuildings, setSelectedBuildings, selectedTags, setSelectedTags, onClear }:CustomerSearchProps) => {

    const [groups, setGroups]:[groups:any[], setGroups:Function] = useState([]);
        
    useEffect(() => {
        const newGroups = createGroups(buildings);
        setGroups(newGroups);
    }, [buildings])

    const clearSelectedTags = () => {
        setSelectedTags([]);
        setSelectedBuildings([]);
    } 

    const onChange = (values:Array<string>, options:Array<any>) => {
        setSelectedTags(values);
        setSelectedBuildings(buildSelectedBuildings(buildings, options));
    }
    
    return (
        <Row style={{ width: 600, placeItems: 'center', position: 'fixed' }}> 
            <Col span={ 13 }>
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    className="width-300"
                    mode="multiple"
                    placeholder="Search"
                    value={selectedTags}
                    onChange={onChange}
                    maxTagCount="responsive"
                    tagRender={(props) => {
                        const { label, closable, onClose } = props;

                        return (
                            <div>
                                <Tag
                                    closable={closable}
                                    onClose={onClose}>
                                    {label}
                                </Tag>
                            </div>
                        )
                    }}
                    maxTagPlaceholder={(values) =>
                        <Tag closable={false}>+ {values.length}</Tag>}>

                    <OptGroup label="Buildings">
                        {buildings.map((building:any, index:any) => {
                            return(
                                <Option data-name={building.name} value={"bldg_" + building.name} key={index}>
                                    {building.name}
                                </Option>
                            )
                        })}
                    </OptGroup>

                    <OptGroup label="Collections">
                        {groups.map((group, index) => {
                            let label : string = "";
                            
                            if (["city", "province", "region", "country", "climate_zone"].includes(group.field)) {
                                label = "Buildings in " + (group.field === "climate_zone" ? "Climate Zone " : "") + group.value;
                            } else if (["facility_type"].includes(group.field)) {
                                label = group.value + " Buildings";
                            } else {
                                return null;
                            }                 
                            return (
                                <Option
                                    data-field={group.field}
                                    data-value={group.value}
                                    value={"grp_" + group.field + "==" + group.value}
                                    key={"g-" + index}>
                                    {label}
                                </Option>
                            )
                        })}
                    </OptGroup>
                    
                </Select>
            </Col>
            
            <Col span={ 4 }>
                {<a href="#" style={{ color: '#EB03AD', fontWeight: '700' }} onClick={clearSelectedTags}>Clear</a>}
            </Col>
        </Row>
    );
}
export default CustomerSearch;
