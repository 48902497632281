import React from 'react';
import './button.css';

export interface ButtonProps {

    primary?: boolean;
    backgroundColor?: string;
    size?: 'small' | 'medium' | 'large';
    width?: number | string;
    children?: React.ReactNode;
    onClick?: () => void;
}


/**
 * Primary UI component for user interaction
 */
export const Button = ({
    primary = false,
    size = 'small',
    width = "var(--size-xl)",
    backgroundColor,
    children,
    onClick
}: ButtonProps) => {
    const mode = primary ? 'aud-button--primary' : 'aud-button--secondary';
    return (
        <div
            className={['aud-button', `aud-button--${size}`, mode].join(' ')}
            style={{ backgroundColor: backgroundColor, width: width }}
            onClick={onClick}>
            { children }
        </div>
    );
};
