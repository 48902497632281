import {ReactNode} from "react";
import {Popover as AntdPopover} from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";

import "./popover.scss";

interface PopoverProps {
    title: ReactNode | (() => ReactNode);
    content: ReactNode | (() => ReactNode);
    trigger?: string;
    placement?: TooltipPlacement;
    children?: JSX.Element;
}

const Popover = ({title, content, trigger="hover", placement="bottomRight", children}: PopoverProps) => {
    return <AntdPopover
        title={title}
        content={content}
        trigger={trigger}
        placement={placement}
    >
        {children}
    </AntdPopover>
}


export default Popover;
