import { Polygon } from 'google-maps-react';
import rooftopData from "./data/rooftop-data.json";

const buildingFootprints = () => {

    let polygons: JSX.Element[] = [];
    for (let i = 0; i < rooftopData.length; i++) {

        let coordinates = rooftopData[i]["geometry"];
        coordinates.forEach((item: any) => {
            polygons.push(
                <Polygon
                    paths={ item }
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#0000FF"
                    fillOpacity={0.35}
                />
            )
        })

    }
    return polygons;
}

export { buildingFootprints };
